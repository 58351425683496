<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          เพิ่มสินค้า
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <!-- -->
        <div class="row mb-5">
          <div class="col-sm-1" />
          <div class="col-sm-11">
            <h3>
              <i class="bi bi-file-earmark-text" style="font-size: 20px"></i>
              รายละเอียดสินค้า
            </h3>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-sm-5">
            <label for="selectMainProduct" class="required form-label col-sm-10"
              >กลุ่มสินค้าหลัก</label
            >
            <select
              class="form-select mb-4"
              v-model="form.selectMainProduct"
              id="validationTooltip01"
              @change="mainProductChange(form.selectMainProduct)"
              required
            >
              <option v-for="item in mainProduct" :key="item" :value="item.id">
                {{ item.main_product_group_code }} |
                {{ item.main_product_group_name }}
              </option>
            </select>
            <div class="invalid-feedback text-end">กรุณาเลือก</div>
          </div>
          <div class="col-sm-5">
            <label
              for="selectSecondaryProduct"
              class="required form-label col-sm-10"
              >กลุ่มสินค้ารอง</label
            >
            <select
              class="form-select mb-4"
              v-model="form.selectSubProduct"
              id="validationTooltip01"
              :style="subProductItem.length < 1 ? 'color: red' : ''"
              required
            >
              <option
                v-for="item in subProductItem"
                :key="item"
                :value="item.id"
              >
                {{ item.sub_product_group_code }} |
                {{ item.sub_product_group_name }}
              </option>

              <option value="" v-if="subProductItem.length < 1">
                {{ textSubPdErr }}
              </option>
            </select>
            <div class="invalid-feedback text-end">กรุณาเลือก</div>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <label for="ProductName" class="required form-label col-sm-10"
            >ชื่อสินค้า</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.product_name"
              type="text"
              class="form-control mb-4"
              id="ProductName"
              name="ProductName"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>

        <div class="row d-flex justify-content-center">
          <label for="detail" class="form-label col-sm-10">รายละเอียด</label>
          <div class="col-sm-10">
            <input
              v-model="form.product_desc"
              type="text"
              class="form-control mb-4"
              id="detail"
              name="detail"
              :style="
                isSubmit
                  ? 'border-color: #ced4da;padding-right: 0.75rem;background: none;'
                  : ''
              "
            />
          </div>
        </div>

        <div class="row d-flex justify-content-center">
          <div class="col-sm-5">
            <label for="barcode" class="form-label required col-sm-10"
              >Barcode</label
            >
            <input
              v-model="form.product_barcode"
              type="text"
              class="form-control mb-4"
              id="barcode"
              name="barcode"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
          <div class="col-sm-5">
            <label for="pog" class="form-label col-sm-10">POG</label>
            <input
              v-model="form.product_pog"
              type="text"
              class="form-control"
              id="pog"
              name="pog"
              :style="
                isSubmit
                  ? 'border-color: #ced4da;padding-right: 0.75rem;background: none;'
                  : ''
              "
            />
          </div>
        </div>

        <div class="row d-flex justify-content-center mt-4 mt-md-0 mt-lg-0">
          <label
            for="ProductName"
            class="required form-label col-12 col-md-10 col-lg-10"
            >รูปสินค้า</label
          >
        </div>

        <div class="row mt-4 mt-md-0 mt-lg-0">
          <div class="col-1 col-md-1 col-lg-1"></div>
          <div class="col-10 col-md-3 col-lg-3">
            <div class="card shadow-sm card-upload-prod">
              <i
                v-if="imgShow"
                class="bi bi-x m-0 p-0"
                id="close-btn"
                style="
                  right: 0px;
                  font-size: 30px;
                  position: absolute;
                  color: red;
                  z-index: 1001;
                  cursor: pointer;
                "
                @click="removePicture()"
              ></i>
              <div
                class="card-body d-flex justify-content-center align-items-center"
              >
                <img
                  v-if="imgShow"
                  class="shadow-sm img-prod"
                  alt="Logo"
                  :src="imgShow ? imgShow : ''"
                />

                <div
                  v-if="!imgShow"
                  style="
                    font-size: 50px;
                    font-weight: bold;
                    position: absolute;
                    color: white;
                    z-index: 1001;
                    cursor: pointer;
                  "
                >
                  +
                </div>
                <div v-if="!imgShow" @click="onClickUpload">
                  <i class="bi bi-image-fill icon-upload">
                    <input
                      @change="onFileChange($event)"
                      type="file"
                      id="input-img"
                      style="display: none"
                      accept="image/png, image/jpeg"
                    />
                  </i>
                </div>
              </div>
            </div>
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>

        <hr class="mt-10 mb-8" style="color: LightGrey" />

        <div class="row mb-5">
          <div class="col-sm-1" />
          <div class="col-sm-11">
            <h3>
              <i class="bi bi-cash-coin" style="font-size: 20px"></i> ราคาสินค้า
            </h3>
          </div>
        </div>

        <div class="row d-flex justify-content-center">
          <div class="col-sm-5">
            <label for="retailPrice" class="required form-label col-sm-10"
              >ราคาทุน</label
            >
            <input
              v-model="form.product_cost_price"
              type="number"
              min="0"
              class="form-control mb-4"
              id="retailPrice"
              name="retailPrice"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
          <div class="col-sm-5">
            <label for="retailPrice" class="required form-label col-sm-10"
              >ราคาขายส่ง</label
            >
            <input
              v-model="form.product_wholesale_price"
              type="number"
              min="0"
              class="form-control mb-4"
              id="retailPrice"
              name="retailPrice"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>

        <div class="row d-flex justify-content-center">
          <div class="col-sm-5">
            <label for="retailPrice" class="required form-label col-sm-10"
              >ราคาขายปลีก</label
            >
            <input
              v-model="form.product_retail_price"
              type="number"
              min="0"
              class="form-control mb-4"
              id="retailPrice"
              name="retailPrice"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
          <div class="col-sm-5">
            <label for="retailPrice" class="form-label col-sm-10"
              >ราคาโปรโมชั่น</label
            >
            <input
              v-model="form.product_promotion_price"
              type="number"
              min="0"
              class="form-control"
              id="retailPrice"
              name="retailPrice"
              :style="
                isSubmit
                  ? 'border-color: #ced4da;padding-right: 0.75rem;background: none;'
                  : ''
              "
            />
          </div>
        </div>

        <hr class="mt-10 mb-8" style="color: LightGrey" />

        <div class="row mb-5">
          <div class="col-sm-1" />
          <div class="col-sm-11">
            <h3>
              <i class="bi bi-box-seam" style="font-size: 20px"></i> คลังสินค้า
            </h3>
          </div>
        </div>

        <div class="row d-flex justify-content-center">
          <div class="col-sm-5">
            <label for="min" class="form-label col-sm-10">Min</label>
            <input
              v-model="form.product_min_stock"
              type="number"
              class="form-control mb-4"
              id="min"
              name="min"
              :style="
                isSubmit
                  ? 'border-color: #ced4da;padding-right: 0.75rem;background: none;'
                  : ''
              "
            />
          </div>
          <div class="col-sm-5">
            <label for="max" class="form-label col-sm-10">Max</label>
            <input
              v-model="form.product_max_stock"
              type="number"
              class="form-control mb-4"
              id="Max"
              name="Max"
              :style="
                isSubmit
                  ? 'border-color: #ced4da;padding-right: 0.75rem;background: none;'
                  : ''
              "
            />
          </div>
        </div>

        <div class="row d-flex justify-content-start">
          <div class="col-sm-1" />
          <div class="col-sm-5">
            <label for="min" class="form-label col-sm-10">ยอดยกมา Qty</label>
            <input
              v-model="form.product_bring_qty"
              type="number"
              class="form-control"
              id="min"
              name="min"
              :style="
                isSubmit
                  ? 'border-color: #ced4da;padding-right: 0.75rem;background: none;'
                  : ''
              "
            />
          </div>
        </div>
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar row justify-content-center">
          <div class="col-sm-10">
            <button
              type="submit"
              class="btn btn-sm btn-light btn-primary ms-3 me-2"
              style="font-size: 14px; font-weight: bold"
              @click="submit"
              :disabled="loading"
            >
              <div
                v-if="loading"
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              ></div>
              บันทึก
            </button>
            <button
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
              @click="cancel"
            >
              ยกเลิก
            </button>
          </div>
        </div>
      </footer>
    </form>

    <AddItem
      :dialogAddItem="dialogAddItem"
      :dataEditItem="dataEditItem"
      @submitAddItem="submitAddItem"
      @closeDialogAddItem="closeDialogAddItem"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";

import whApi from "@/api/warehouse/";
import userApi from "@/api/user/";
import apiConfig from "@/config/api";

import { Decode, Encode } from "@/services";

import AddItem from "../../components/upload/dialogCropImg.vue";

export default {
  components: { AddItem },
  setup() {
    document.title = "BELUCA | เพิ่มสินค้า";
  },
  data: () => ({
    loading: false,
    isSubmit: false,

    form: {
      selectMainProduct: "",
      selectSubProduct: "",

      product_name: "",
      product_desc: "",
      product_barcode: "",
      product_pog: "",
      product_pic: "",
      product_cost_price: "",
      product_wholesale_price: "",
      product_retail_price: "",
      product_promotion_price: "",
      product_min_stock: "",
      product_max_stock: "",
      product_bring_qty: "",
      product_first_bring_qty: "",
      is_cancel: "0",
      status: "1",
      approved_by: "",

      userId: "",
    },
    ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,

    mainProduct: [],
    subProduct: [],

    subProductItem: [],
    textSubPdErr: "",

    dialogAddItem: false,
    dataEditItem: "",

    imgName: "",
    imgShow: "",
    fileForCreate: "",
  }),

  created() {
    this.getMainProduct();
    this.getSubProduct();
  },

  methods: {
    async getMainProduct() {
      let responseData = [];
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      try {
        responseData = await whApi.mainProduct.getAll({
          companyId: companyId,
          companyBranchId: companyBranchId,
        });
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.mainProduct = responseData.data;
        // this.form.selectMainProduct = responseData.data[0].id;
      }
    },
    async getSubProduct() {
      let responseData = [];
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      try {
        responseData = await whApi.subProduct.getAll({
          companyId: companyId,
          companyBranchId: companyBranchId,
        });
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.subProduct = responseData.data;
        // this.form.selectSubProduct = responseData.data[0].id;
      }
    },

    async submit() {
      let createResponse = [];

      await this.validateForm();
      await this.checkFormIsEmpty();
      this.isSubmit = true;

      const userId = Decode.decode(localStorage.getItem("boss"));

      if (this.isValid) {
        this.loading = true;

        this.form = {
          ...this.form,
          product_first_bring_qty: this.form.product_bring_qty,
          mainProductGroupId: this.form.selectMainProduct,
          subProductGroupId: this.form.selectSubProduct,
          product_in_transit: 0,
          companyId: parseInt(localStorage.getItem("companyId")),
          companyBranchId: parseInt(localStorage.getItem("companyBranchId")),
          userId: userId,
        };

        createResponse = await whApi.product.create(this.form);
        if (createResponse.response_status === "SUCCESS") {
          const resUpload = this.uploadPic(createResponse.data);

          if (resUpload) {
            Swal.fire({
              icon: "success",
              title: "เพิ่มสำเร็จ",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              this.$router.push({
                path: "/warehouse/WH4",
                query: {},
              });
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "เพิ่มสำเร็จ, แต่อัพโหลดรูปภาพไม่สำเร็จ",
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              this.$router.push({
                path: "/warehouse/WH4",
                query: {},
              });
            });
          }
          this.loading = false;
        } else {
          Swal.fire({
            icon: "error",
            title: "เพิ่มไม่สำเร็จ !",
            text: createResponse.message,
            showConfirmButton: false,
            timer: 2500,
          });
          this.loading = false;
        }
      }
    },

    checkFormIsEmpty() {
      !this.form.product_name
        ? (this.isValid = false)
        : !this.form.product_cost_price
        ? (this.isValid = false)
        : !this.form.product_wholesale_price
        ? (this.isValid = false)
        : !this.form.product_retail_price
        ? (this.isValid = false)
        : !this.form.selectMainProduct
        ? (this.isValid = false)
        : !this.form.selectSubProduct
        ? (this.isValid = false)
        : !this.form.product_barcode
        ? (this.isValid = false)
        : (this.isValid = true);
    },
    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.push({
        path: "/warehouse/WH4",
        query: {},
      });
    },
    mainProductChange(val) {
      const filterSubProduct = this.subProduct.filter(
        (data) => data.mainProductGroupId === val
      );
      if (filterSubProduct.length > 0) {
        this.subProductItem = filterSubProduct;
        this.form.selectSubProduct = filterSubProduct[0].id;
        this.textSubPdErr = "";
      } else {
        this.textSubPdErr = "ไม่มีกลุ่มสินค้ารอง ภายใต้กลุ่มสินค้าหลักที่เลือก";
        this.subProductItem = [];
        this.form.selectSubProduct = "";
      }
    },

    // product
    addItem(pic) {
      this.dialogAddItem = true;
      this.dataEditItem = pic;
    },

    closeDialogAddItem() {
      this.dialogAddItem = false;
    },

    async submitAddItem(item) {
      console.log(item, 234);
      this.closeDialogAddItem();
      this.imgShow = item.canvas.toDataURL(this.image.type);
      let file = await this.dataURLtoFile(this.imgShow, this.imgName);
      if (file) {
        // this.uploadPic(file);
        this.fileForCreate = file;
      }
    },

    // upload pic
    onClickUpload() {
      document.getElementById("input-img").click();
    },

    async onFileChange(e) {
      const selectImg = e.target.files[0];
      this.imgName = selectImg.name;
      const response = await this.createBase64Img(selectImg);

      if (response) {
        this.image = response;
        this.addItem(response);
      }
    },

    async createBase64Img(file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    },

    async removePicture() {
      this.imgShow = "";
    },

    async dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },

    async uploadPic(item) {
      const companyBranchId = localStorage.getItem("companyBranchId");

      const formData = new FormData();
      formData.append("file", this.fileForCreate);
      formData.append("type", "upload-file");
      formData.append("name", this.fileForCreate.name);
      formData.append("size", this.fileForCreate.size);
      formData.append("productId", item.id);

      const response = await userApi.upload.upload(formData);

      if (response.response_status === "SUCCESS") {
        return true;
      } else {
        Swal.fire({
          icon: "error",
          title: `อัพโหลดรูปภาพไม่สำเร็จ`,
          confirmButtonText: "ตกลง",
        });
        return false;
      }
    },
  },
};
</script>
